import axios from 'axios';

export const cmsOperationMiddleware = store => next => action => {
  // Proceed with the action as usual
  const result = next(action);

  // Check if the dispatched action is a CMS operation
  if (
    action.type.includes('UPDATE') ||
    action.type.includes('DELETE') ||
    action.type.includes('CREATE') ||
    action.type.includes('UPLOAD') ||
    action.type.includes('ADD') ||
    action.type.includes('SEND_PHOTOS') ||
    action.type.includes('REMOVE') ||
    action.type.includes('CHANGE')
  ) {
    // Perform the API call for revalidation
    axios
      .get(
        'https://sve-za-vas-dom-site.vercel.app/api/revalidate?secret=15f1c19ac05428e3181e6c44be2d67ce&tag=all'
      )
      .then(response => {
        console.log('CMS revalidated successfully', response);
      })
      .catch(error => {
        console.error('CMS revalidation failed', error);
      });
  }

  return result;
};
